import React, { useContext } from "react";
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";

import ReactPlayer from 'react-player'
import Carousel from 'react-grid-carousel'

import HappyKids from "../Assets/Images/Asset 50.png";
import HeaderCloudLeft from "../Assets/Images/Asset 48.png";
import HeaderCloudRight from "../Assets/Images/Asset 47.png";
import Thropy from "../Assets/Images/Asset 37.png";

import Carousel1 from "../Assets/Images/1.png";
import Carousel2 from "../Assets/Images/2.png";
import Carousel3 from "../Assets/Images/3.png";
import Carousel4 from "../Assets/Images/4.png";

import Prize1 from "../Assets/Images/Asset 17.png";
import Prize2 from "../Assets/Images/Asset 16.png";
import Prize3 from "../Assets/Images/Asset 15.png";

import Prize1A from "../Assets/Images/Asset 69.png";
import Prize1B from "../Assets/Images/Asset 68.png";
import Prize1C from "../Assets/Images/Asset 67.png";
import Prize2A from "../Assets/Images/Asset 72.png";
import Prize2B from "../Assets/Images/Asset 71.png";
import Prize2C from "../Assets/Images/Asset 70.png";
import Prize3A from "../Assets/Images/Asset 66.png";
import Prize3B from "../Assets/Images/Asset 65.png";
import Prize3C from "../Assets/Images/Asset 64.png";
import LineImage from "../Assets/Images/Asset 34.png";
import LineContent from "../Assets/Images/Asset 33.png";
import Seo from '../components/seo/Seo';
import { navigate } from "gatsby" 
import '../styles/SchoolCompetitionPage.scss';

const SchoolCompetitionPage = () => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  return (
    <Layout>
        <Seo 
            title='Titik Pintar - Kompetisi Sekolah Pintar, Buat jadi Juara'
            description='Kompetisi Sekolah Pintar adalah suatu kegiatan kompetisi yang diadakan oleh Titik Pintar, untuk mengapresiasi Siswa dan Sekolah yang berprestasi.'
            keywords='Kompetisi, Sekolah Pintar, Kompetisi Sekolah Pintar, Titik Pintar, Sekolah Berprestasi'
            />
       <div className="school-competition-page">
            <div className="header">
                <h1>Yuk ikutan Kompetisi Sekolah Pintar, <br/> jadilah sang Juara!</h1>
                <img src={HappyKids} alt="Titik Pintar kids" className="kids scale-in-center" />
                <div className="clouds">
                    <img src={HeaderCloudLeft} alt="Clouds"/>
                    <img src={HeaderCloudRight} alt="Clouds"/>
                </div>
            </div>

            <div className="video-explanation">
                <div className="for">
                    <h2>Untuk Sekolah</h2>
                    <ReactPlayer url='https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/marketing/Titik+Pintar+School+Movie.mp4' controls={true} width='100%' height='65%'/>
                    {/* <span onClick={() => navigate('/school-competition-schedule')}>Daftar sebagai sekolah</span> */}
                </div>
                <div className="for">
                    <h2>Untuk Orang Tua / Anak</h2>
                    <ReactPlayer url="https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/marketing/School+Movie+For+Kids+And+Parents.mp4" controls={true} width='100%' height='65%'/>
                    {/* <span onClick={() => navigate('/bantuan')}>Hubungi Kami</span> */}
                </div>
            </div>

            <div className="thropy-explanation">
                <div className="thropy">
                    <img src={Thropy} alt="Thropy"/>
                </div>
                <p>Kompetisi Sekolah Pintar adalah suatu kegiatan kompetisi yang diadakan oleh Titik Pintar, untuk mengapresiasi Siswa dan Sekolah yang berprestasi. Kegiatan ini diadakan 6-8 kali dalam 1 tahun, dengan periode kompetisi berlangsung selama 1 bulan.</p>
            </div>

            <div className="mechanism-explanation">
                <h2>Mekanisme Kompetisi Sekolah Pintar</h2>
                <img src={LineImage} className="line-image" alt="Line Imag"/>
                <img src={LineContent} className="line-content" alt="Line Cont"/>
                <Carousel
                    cols={4}
                    rows={1}
                    gap={30}
                    scrollSnap={true}
                    mobileBreakpoint={10}
                    responsiveLayout={[
                    {
                        breakpoint: 1000,
                        cols: 2,
                        rows: 1,
                        gap: 30,
                        loop: true,
                    },
                    {
                        breakpoint: 800,
                        cols: 1,
                        rows: 1,
                        gap: 50,
                        loop: true,
                    }
                    ]}
                >
                    <Carousel.Item>
                        <img width="100%" src={Carousel1} alt="Validasi Diri"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img width="100%" src={Carousel2} alt="Validasi Diri"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img width="100%" src={Carousel3} alt="Validasi Diri"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img width="100%" src={Carousel4} alt="Validasi Diri"/>
                    </Carousel.Item>
                </Carousel>
                <p>Para Siswa yang menggunakan aplikasi Titik Pintar secara otomatis akan terdaftar dalam kompetisi apabila sekolah dari siswa tersebut terdaftar dan bergabung dalam kompetisi ini. Setiap minggu dan di ahkir periode, akan ada pengumuman juara dan akan mendapatkan hadiah berupa:</p>
            </div>

            <div className="prize-explanation">
                <div className="prize-content">
                    <img src={Prize1} width="50%" className="header_weekly" alt="Hadiah Mingguan" />
                    <div className="content">
                        <Carousel
                            cols={3}
                            rows={1}
                            gap={30}
                            scrollSnap={true}
                            mobileBreakpoint={10}
                            responsiveLayout={[
                            {
                                breakpoint: 765,
                                cols: 1,
                                rows: 1,
                                gap: 50,
                                loop: true,
                            }
                            ]}
                        >
                            <Carousel.Item>
                            <img src={Prize1A} width="100%" alt="Juara Mingguan 1"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize1B} width="100%" alt="Juara Mingguan 2"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize1C} width="100%" alt="Juara Mingguan 3"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <h2>Setiap Sekolah, Setiap Minggunya</h2>
                </div>
                <div className="prize-content">
                    <img src={Prize2} width="40%" className="header_kids" alt="Hadiah Mingguan" />
                    <div className="content">
                        <Carousel
                            cols={3}
                            rows={1}
                            gap={30}
                            scrollSnap={true}
                            mobileBreakpoint={10}
                            responsiveLayout={[
                            {
                                breakpoint: 765,
                                cols: 1,
                                rows: 1,
                                gap: 50,
                                loop: true,
                            }
                            ]}
                        >
                            <Carousel.Item>
                            <img src={Prize2A} width="100%" alt="Juara Utama 1"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize2B} width="100%" alt="Juara Utama 2"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize2C} width="100%" alt="Juara Utama 3"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <h2>Setiap Sekolah, Setelah 4 Minggu</h2>
                </div>
                <div className="prize-content">
                    <img src={Prize3} width="60%" className="header_school" alt="Hadiah Mingguan" />
                    <div className="content">
                        <Carousel
                            cols={3}
                            rows={1}
                            gap={30}
                            scrollSnap={true}
                            mobileBreakpoint={10}
                            responsiveLayout={[
                            {
                                breakpoint: 765,
                                cols: 1,
                                rows: 1,
                                gap: 50,
                                loop: true,
                            }
                            ]}
                        >
                            <Carousel.Item>
                            <img src={Prize3A} width="100%" alt="Juara Sekolah 1"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize3B} width="100%" alt="Juara Sekolah 2"/>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={Prize3C} width="100%" alt="Juara Sekolah 3"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <h2>3 Sekolah Terbaik</h2>
                </div>
                <div className="competition-winner">
                    <h2>Juara Kompetisi Sekolah Pintar Sebelumnya</h2>
                    <div className="winner-video">
                        <div className="banner">
                            <h3>Kompetisi 2</h3>
                            <b>November</b>
                        </div>
                        <ReactPlayer url="https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/School+Competition+All+Winners+(Kebon+Kacang+01%2C+Manggarai+09%2C+Kampung+Bali+01)+(small)+Batch+2.mp4" controls={true} width='90%' height='auto' style={{marginBottom: '100px'}} />

                        {/* <div className="for">
                            <span onClick={() => navigate('/school-competition-schedule')}>Daftar sebagai sekolah</span>
                        </div>
                        <div className="for">
                            <span onClick={() => navigate('/bantuan')}>Hubungi Kami</span>
                        </div> */}
                    </div>

                    <div className="winner-video">
                        <div className="banner">
                            <h3>Kompetisi 1</h3>
                            <b>Oktober</b>
                        </div>
                        <ReactPlayer url="https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/School+Competition+All+Winners+(Juara+1+2+3)+Batch+1.mp4" controls={true} width='90%' height='auto' style={{marginBottom: '100px'}} />

                        {/* <div className="for">
                            <span onClick={() => navigate('/school-competition-schedule')}>Daftar sebagai sekolah</span>
                        </div>
                        <div className="for">
                            <span onClick={() => navigate('/bantuan')}>Hubungi Kami</span>
                        </div> */}
                    </div>

                   

                </div>
            </div>
        </div>
    </Layout>
  );
};

export default SchoolCompetitionPage;

